import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

const List = ({ input, selectLanguage }) => {
const[selectedZone,SetselectedZone]=useState("AST")
  const SelectedValue = (zone) => {
    SetselectedZone(zone);
  };
const zones=["AST","CST","EST","PST","AKST","HST","UTC"]
  return (
    <div  style={{maxHeight:''}}>
      {zones.map((zone, index) =>
     
        <div
          style={zone === selectedZone ? { marginTop: "10px", background: "#1c2029" } : { marginTop: "10px" }}
          onClick={() => SelectedValue(zone)}
          className="itemshover"
        >
          <div style={{  padding: "10px", overFlow: "hidden", width: "100%"}}>
              
              <div>{zone?.toUpperCase()}</div>
              
            <div>
             
            </div>
          </div>
        </div>
    )}
    </div>
  );
};

export default List;
