import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import List from "./List";
import { HiGlobeAlt } from "react-icons/hi";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../../redux/action/setLanguageAction";
import { RiSearchLine } from "react-icons/ri";
import { FiX } from "react-icons/fi";
import { RiArrowDropDownLine } from "react-icons/ri";

const TranslationDropdown = () => {
  const [inputText, setInputText] = useState("");
  const language = useSelector((state) => state.LANGUAGE.Selected);
  const dispatch = useDispatch();

  let inputHandler = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const setSelectedLang = (lang) => {
    localStorage.setItem("selectedLanguage", JSON.stringify(lang));
    dispatch(setLanguage(lang));
  };

  return (
    <Dropdown
      title={
        <div className="main">
          <div className="dynamic-search-input" style={
            {width:"360px",margin: "auto", display: "block" }

          }>
            <input
              style={{ border: "none",width:"100%",height:"40px" }}
              id="outlined-basic"
              value={inputText}
              onChange={inputHandler}
              variant="outlined"
              placeholder="Search For Language"
              fullWidth
            />
            {inputText?.length !== 0 ? (
              <FiX
                className="input-icon"
                onClick={() => {
                  setInputText("");
                }}
              />
            ) : (
              <RiSearchLine className="input-icon" />
            )}
          </div>
        </div>
      }
      className="Translation-dropdown"
      label={
          <div className="timezone-border" style={{ display: "flex", flexDirection: "row" }}>
          <HiGlobeAlt style={{ fontSize: "1.5em", marginBottom: "-5px",marginLeft: "5px" }}  />
          <div style={{ marginLeft: "2px", marginTop: 3 }}>{language.langCode}</div>
          <RiArrowDropDownLine style={{ fontSize: "2rem", marginBottom: "-5px", marginTop: "-5px" }} />
        </div>
        
      }
      render={() => (
        <>
          <List  input={inputText} selectLanguage={setSelectedLang} />
        </>
      )}
      isAnimation={true}
    />
  );
};

export default TranslationDropdown;
