import React, { useContext, useEffect, useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import List from "./List";
import { HiOutlineClock } from "react-icons/hi";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../../../redux/action/setLanguageAction";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiX } from "react-icons/fi";

const TimezoneDropdown = () => {
  const [inputText, setInputText] = useState("");
  const language = useSelector((state) => state.LANGUAGE.Selected);
  const dispatch = useDispatch();
  const setSelectedLang = (lang) => {
    localStorage.setItem("selectedLanguage", JSON.stringify(lang));
    dispatch(setLanguage(lang));
  };

  return (
    <Dropdown
      className="Zone-dropdown "
      label={
        <div className="timezone-border" style={{ display: "flex", flexDirection: "row",  }}>
          <HiOutlineClock style={{ fontSize: "1.5em", marginBottom: "-5px" ,marginLeft: "5px"}} />
          <div style={{ marginLeft: "2px", marginTop: 3 }}>GET</div>
          <RiArrowDropDownLine style={{ fontSize: "2rem", marginBottom: "-5px", marginTop: "-5px" }} />
        </div>
      }
      render={() => (
        <>
          <List selectLanguage={setSelectedLang} />
        </>
      )}
      isAnimation={true}
    />
  );
};

export default TimezoneDropdown;
