import React, { useEffect, useContext } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { AuthRoute, GuestRoute } from "./utils/CustomRouteTypes";
import Settings from "./components/Settings/Settings";
import UserProfile from "./components/UserProfile/UserProfile";
import Logout from "./components/Logout/Logout";
import Notifications from "./components/Notifications/Notifications";

import BrowseGames from "./components/BrowseGames/BrowseGames";
import Contact from "./components/Contact/Contact";
import DynamicPage from "./components/DynamicPage/DynamicPage";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import { AppContext } from "./context/AppContext";
// import DropDownTest from "./components/_common/DemoTest/DropDownTest";
import Overview from "./components/Pages/Overview/Overview";
import MyOrganizations from "./components/Pages/MyOrganizations/MyOrganizations";
import Recents from "./components/Pages/Recents/Recents";
import Sharedwithme from "./components/Pages/SharedWithMe/SharedWithMe";
import Templates from "./components/Pages/Templates/Templates";
import Trash from "./components/Pages/Trash/Trash";
// Global router
const AppRouter = () => {
  const { pathname } = useLocation();
  const { showRouterComponent } = useContext(AppContext);
  const { selectedGame } = useContext(AppContext);
  const routes = [
    {
      key: "Overview",
      pathName: "/overview",
      content: <Overview />,
    },
    {
      key: "My Competitions",
      pathName: "/my-organizations",
      content: <MyOrganizations />,
    },
    // {
    //   key: "Shared With Me",
    //   pathName: "/shared-with-me",
    //   content: <Sharedwithme />,
    // },
    {
      key: "Recents",
      pathName: "/recents",
      content: <Recents />,
    },
    {
      key: "Templates",
      pathName: "/templates",
      content: <Templates />,
    },
    {
      key: "Trash",
      pathName: "/trash",
      content: <Trash />,
    },
   
  ];
  // Scroll to top whenever the route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // if (!showRouterComponent) return null;
  return (
    <Switch>
      {/* <Route exact path="/dropdown">
        <DropDownTest />
      </Route> */}
      <Route exact path="/">
        <Overview />
      </Route>
      <AuthRoute path="/logout">
        <Logout />
      </AuthRoute>
      <AuthRoute path="/account">
        <Settings />
      </AuthRoute>
      <Route path="/s/:slug">
        <DynamicPage />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/user/:id">
        <UserProfile />
      </Route>
     
      <Route path="/notifications">
        <Notifications />
      </Route>
      <Route path="/p/:slug">
        <DynamicPage />
      </Route>
      <Route path="/browse-games">
        <BrowseGames />
      </Route>
      {routes.map((route) => {
        // if (!selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[route.key])) {
          return (
            <Route path={route.pathName} exact={route.exact}>
              {route.content}
            </Route>
          );
        // }
      })}
      {/* {authRoutes.map((route) => {
        if (!selectedGame || !selectedGame.accessibility || (selectedGame && selectedGame.accessibility[route.key])) {
          return <AuthRoute path={route.pathName}>{route.content}</AuthRoute>;
        }
      })} */}
      {/* 404 */}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
};
export default AppRouter;