import React, { createContext, useState, useEffect, useReducer } from "react";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import verifyToken from "./utils/verifyToken";
import userReducer from "./reducers/userReducer";
import subscribeNotifications from "./utils/subscribeNotifications";
import GhostContentAPI from "@tryghost/content-api";

export const AppContext = createContext();
export const WithAppContext = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [showAuth, setShowAuth] = useState(false);
  const [showRouterComponent, setShowRouterComponent] = useState(true);
  const [userState, userDispatch] = useReducer(userReducer, false);
  const [selectedGame, setSelectedGame] = useState(JSON.parse(localStorage.selectedGame || false));
  // const [filters, setFilters] = useState();
  const [ghost] = useState(
    new GhostContentAPI({
      url: process.env.REACT_APP_GHOST_URL,
      key: process.env.REACT_APP_GHOST_CONTENT_KEY,
      version: "v3",
    })
  );
  // Check authentication & set user data on load and each page change
  const history = useHistory();
  const { pathname } = useLocation();

  const remountDom = () => {
    setShowRouterComponent(false);
    setTimeout(() => setShowRouterComponent(true), 10);
  };

  // Authentication setup
  useEffect(() => {
    const validTokenData = verifyToken();

    if (validTokenData) {
      if (!authenticated || !userState) {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/auth`)
          .then(async ({ data }) => {
            await subscribeNotifications(data.pushSubscriptions); // setup notifications
            setAuthenticated(true);
            userDispatch({
              type: "load",
              payload: {
                ...validTokenData,
                ...data,
              },
            });
            setLoading(false);
          })
          .catch(() => {
            localStorage.removeItem("sessionToken");
            history.push("/");
            setLoading(false);
          });
      }
    } else {
      // Clear authentication state
      setAuthenticated(false);
      if (Object.keys(userState).length) {
        userDispatch({ type: "load", payload: false });
      }

      setLoading(false);
    }
  }, [history, pathname, authenticated, userState]);

  // get games, and set the first one as the selected game, if it isn't already been retrieved from LS
  useEffect(() => {
    if (!selectedGame) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
        // setSelectedGame(data[0]);
        setSelectedGame(data.filter((gamdata) => gamdata.defaultGame === true)[0]);
      });
    } else {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${selectedGame._id}`).then(({ data }) => {
        if (data.updatedAt !== selectedGame.updatedAt) {
          //Update time change
          setSelectedGame(data);
        }
      });
    }
  }, [selectedGame]);

  // Update LS when selected game is changed
  useEffect(() => {
    selectedGame && localStorage.setItem("selectedGame", JSON.stringify(selectedGame));
  }, [selectedGame]);

  useEffect(() => {
    userState && localStorage.setItem("timezone", userState?.timezone);
  }, [userState]);

  const context = {
    authenticated,
    setAuthenticated,
    showAuth,
    setShowAuth,
    ghost,
    selectedGame,
    setSelectedGame,
    showRouterComponent,
    remountDom,
    // filters,
    // setFilters,
    user: { state: userState, dispatch: userDispatch },
  };

  return loading ? "Loading" : <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
