import React from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';
import CreateCompetitionModalFunctionality from './CreateCompetitionModalFunctionality';
const CreateCompetitionModal = ({ open, onClose }) => {
  return (
    <Modal isOpen={open} className="modal" contentLabel="Example Modal">
      <div className="modal-close" onClick={() => onClose(false)}>
        <RiCloseLine />
      </div>
      <div className="modal-header" style={{ textAlign: 'left' }}>
        <h2>Create Organization</h2>
        <h3>Your organization logo and Information</h3>
      </div>
      <div className="modal-body">
        <CreateCompetitionModalFunctionality onClose={onClose} />
      </div>
    </Modal>
  );
};

export default CreateCompetitionModal;
