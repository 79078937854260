import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Axios from "axios";
import EditCSGOInGameName from "./EditCSGOInGameName";
import { RiDeleteBin2Line, RiEyeLine } from "react-icons/ri";
import { AppContext } from "../../../../../context/AppContext";
import useTranslated from "../../../../../helpers/translationHelper";

const ProfileListItem = ({ _id, type, avatarImage, name, inGameName = null, description, headerImage, game = {}, afterDelete, afterUpdateingamename }) => {
  const { selectedGame } = useContext(AppContext);
  game = selectedGame
  const nameTrans = useTranslated('id_settings_(my_players_and_coaches)_6')
  const gameTrans = useTranslated('id_settings_(my_players_and_coaches)_7')
  const viewTrans = useTranslated('id_settings_(my_players_and_coaches)_8')
  const deleteTrans = useTranslated('id_settings_(my_players_and_coaches)_10')
  const onDelete = async () => {
    if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/${type === "player" ? "players" : "coaches"}/${_id}`);
        toast.success("Successfully deleted");
        afterDelete();
      } catch (e) {
        toast.error(`Whoops, there was a problem deleting ${type}`);
      }
    }
  };

  const onUpdateName = async (newName, about, images, type) => {
    let payload = {};
    if (images && images.logo) {
      payload.avatarImage = images.logo;
    }
    if (images && images.banner) {
      payload.headerImage = images.banner;
    }
    if (newName) {
      payload.inGameName = newName;
    }
    if (about) {
      payload.description = about;
    }
    try {

      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/${type === "player" ? "players" : "coaches"}/${_id}`, { payload: payload });
      toast.success("Successfully updated");
      afterUpdateingamename(payload);
    } catch (e) {
      toast.error(`Whoops, there was a problem updating ${type}`);
    }
  }

  return (
    <>
    {
      game ? (
          <div className="game-profile-list">
          <div className="game-profile-list-th">
            <div className="row tiny-gutter-row">
              <div className="col-5">
                <span className="text-12 faded bold uppercase">{/* Name */}{nameTrans}</span>
              </div>
              <div className="col-4">
                <span className="text-12 faded bold uppercase">{/* Game */}{gameTrans}</span>
              </div>
              <div className="col-3"></div>
            </div>
          </div>

          <div className="game-profile-list-item">
            <div className="row tiny-gutter-row">
              <div className="col-4">
                {avatarImage ? (
                  <div className="game-profile-lg-image" style={{ backgroundImage: `url(${avatarImage})` }} />
                ) : (
                  <div className="game-profile-lg-image profile-image-with-initials">{name.charAt(0)}</div>
                )}
                <span className="text-14 bold trunacate-60">
                  {
                    type === "player" ? game.shortName === "CSGO" ? inGameName : name : name
                  }
                  {/* {game.shortName === "CSGO" && type === "player" ? <>{inGameName} 
                    <EditCSGOInGameName 
                      game={game.shortName}
                      type={type}
                      inGameName={inGameName} 
                      currentavatarImage={avatarImage}
                      currentabout={description}
                      currentheaderImage={headerImage}
                      onUpdateName={(newName,about,images) => onUpdateName(newName,about,images,type)} 
                    /></> : <>{name} 
                    <EditCSGOInGameName 
                      game={game.shortName}
                      type={type}
                      inGameName={null} 
                      currentavatarImage={avatarImage}
                      currentabout={description}
                      currentheaderImage={headerImage}
                      onUpdateName={(newName,about,images) => onUpdateName(newName,about,images,type)} 
                    /></>}     */}
                </span>
              </div>
              <div className="col-4">
                <div className="game-profile-sm-image" style={{ backgroundImage: `url(${game.whiteIcon})` }} />
                <span className="text-12 bold">{game.name}</span>
              </div>
              <div className="col-2 actions-shift">
                <Link to={`/${type === "player" ? "game-profile" : "coaches"}/${_id}`} className="primary-link view-profile">
                  <RiEyeLine size={`0.8em`} title={`View Profile`} />
                  <text className="view-text">{/* View */}{viewTrans}</text>
                </Link>

                {'    '}
                <EditCSGOInGameName
                  game={game.shortName}
                  type={type}
                  inGameName={inGameName}
                  currentavatarImage={avatarImage}
                  currentabout={description}
                  currentheaderImage={headerImage}
                  onUpdateName={(newName, about, images) => onUpdateName(newName, about, images, type)}
                />
              </div>
              <div className="col-2">
                {afterDelete && (
                  <div className="edit-profile">
                    <RiDeleteBin2Line size={`0.8em`} title={`Delete`} className="primary-link color-danger" onClick={() => onDelete()} />
                    <text className="delete-text color-danger" onClick={() => onDelete()}>{/* Delete */}{deleteTrans}</text>
                  </div>
                  // <button className="text-12 primary-link color-danger" onClick={onDelete}>
                  //   Delete
                  // </button>
                )}
              </div>
            </div>
          </div>
        </div> ) : <></> 
      }
    </>
  );
};

export default ProfileListItem;
