import React, { useState, useRef } from 'react';
import Dropzone from 'react-dropzone';
import Axios from 'axios';
import { toast } from 'react-toastify';
import Loading from '../../_common/Loading/Loading';
import useTranslated from '../../../helpers/translationHelper';
import { GoCloudUpload } from 'react-icons/go';
import { AiTwotoneCamera } from 'react-icons/ai';
const UploadLogobox = ({
  name,
  width,
  height,
  textunderHeading,
  textunderButton,
  reuploadbuttontext,
  setNameinput,
  nameinput
}) => {
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(false);
  const lableDragAndDrop = useTranslated('id_pop-up_-_upload_image_4');
  const handleDrop = (dropped) => {
    // Get image from drop
    const image = dropped[0];
    const imageObj = new Image();
    imageObj.src = window.URL.createObjectURL(image);
    // imageObj.addEventListener("load", isTransparent, true);

    const file = new File([image], `${name}.png`, {
      type: 'image/png'
    });
    const formData = new FormData();
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    };
    formData.append('image', file);
    imageObj.onload = () => {
      const imageSize = image && image.size;
      const imageWidth = imageObj.width;
      const imageHeight = imageObj.height;
      // Is image less than 8MB?
      if (imageSize > 8000000) return toast.error('File must be smaller than 8MB');

      // Is image the minimum dimensions?
      if (imageWidth > width || imageHeight > height)
        return toast.error(`File must be at least ${width}px x ${height}px in size`);
      setImage(URL.createObjectURL(image));
      Axios.post(`https://dev-api-core.leagues.gg/api/upload`, formData, config)
        .then(({ data }) => {
          if (name === 'logo') {
            setNameinput({ ...nameinput, logo: data.file });
          }
          if (name === 'cover') {
            setNameinput({ ...nameinput, cover: data.file });
          }
          if (name === 'thumbnail') {
            setNameinput({ ...nameinput, thumbnail: data.file });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };

  const onClickReupload = (name) => {
    if (document.getElementById(`${name}`)) {
      document.getElementById(`${name}`).click();
    }
  };
  return (
    <>
      <div className="edit-image-body">
        <div class="form-row">
          <label for="logo">{name}</label>
          <p style={{ color: 'rgb(153, 153, 153)' }}>
            {textunderHeading ? textunderHeading : null}
          </p>
        </div>
        <div style={image ? { position: 'relative' } : { display: 'none' }}>
          <img style={{ width: `100%` }} src={image} alt="not found" />
          <div className={`save-buttons reuploadbutton`} useRef={`${name}`}>
            <button
              className="button"
              style={{ background: '#68717E 0% 0% no-repeat padding-box', borderRadius: '2px' }}
              onClick={() => onClickReupload(name)}
              disabled={uploading}>
              <AiTwotoneCamera style={{ fontSize: '1.5em', marginBottom: '-5px' }} />
              {uploading ? <Loading /> : reuploadbuttontext ? reuploadbuttontext : null}
            </button>
          </div>
        </div>
        <div className="image-dropzoneonhover" style={image ? { display: 'none' } : null}>
          <Dropzone
            multiple={false}
            accept={['image/jpeg', 'image/jpg', 'image/png', 'image/gif']}
            onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <div className={`image-dropzone uplodimglogo`} id={`${name}`} {...getRootProps()}>
                <input {...getInputProps()} />
                <div style={{ width: '100%', border: '0px' }}>
                  <GoCloudUpload style={{ width: '100%', height: '25px', border: '0px' }} />
                  <label style={{ width: '100%', color: 'white', border: '0px' }}>
                    Upload image
                  </label>
                  <div style={{ width: '100%', border: '0px' }}>{lableDragAndDrop}</div>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
        <div style={{ marginBottom: '15px' }}>{textunderButton ? textunderButton : null}</div>
      </div>
    </>
  );
};

export default UploadLogobox;
