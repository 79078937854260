import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { AppContext } from '../../../context/AppContext';
import UploadLogobox from '../EditImageModal/UploadLogobox';
import { toast } from 'react-toastify';

const CreateCompetitionModalFunctionality = ({ onClose }) => {
  const { user } = useContext(AppContext);
  const [imgahetyle, setImanetype] = useState({});
  const [nameinput, setNameinput] = useState({ name: '', logo: '', cover: '', thumbnail: '' });

  const updateUser = async (imageUrl) => {
    const type = { type: 'avatarImage' };
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/image`, {
        type,
        image: imageUrl
      });
      user.dispatch({ type: 'update', payload: { [type]: imageUrl } });
    } catch (err) {
      toast.error('There was a problem updating your account');
    }
  };
  const onClickCreate = () => {
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/organizer`, nameinput);
    onClose(false);
  };
  return (
    <div>
      <div className="form-row">
        <div class="form-row">
          <label for="email">NAME</label>
          <input
            type="email"
            id="email"
            name="email"
            autocomplete="email"
            class="form-input "
            value={nameinput.name}
            onChange={(e) => setNameinput({ ...nameinput, name: e.target.value })}
            placeholder="Organization Name"
          />
        </div>
      </div>

      <UploadLogobox
        name={'logo'}
        width={2400}
        height={600}
        setNameinput={setNameinput}
        nameinput={nameinput}
        textunderButton={
          <div>
            Recommended file Format:{' '}
            <strong style={{ color: 'rgb(153, 153, 153)', fontWeight: '100' }}>
              PNG logo with transparent background and high contrast to dark background is
              recommended
            </strong>
          </div>
        }
        reuploadbuttontext={'UPDATE LOGO'}
      />
      <UploadLogobox
        name={'cover'}
        width={2400}
        height={600}
        setNameinput={setNameinput}
        nameinput={nameinput}
        textunderHeading="This cover is displayed at top of the competition page"
        textunderButton={
          <div>
            <strong style={{ color: 'rgb(153, 153, 153)', fontWeight: '100' }}>
              Recommended size:{' '}
            </strong>
            2400 x 600 pixels
          </div>
        }
        reuploadbuttontext={'UPDATE COVER'}
      />
      <UploadLogobox
        name={'thumbnail'}
        width={1280}
        height={720}
        setNameinput={setNameinput}
        nameinput={nameinput}
        textunderHeading={
          <div>
            This thumbnail is what organizers see first when brownsing for competitions on the
            platform. If no thumbnail is uploaded , the logo will be shown
          </div>
        }
        textunderButton={
          <div>
            <p>
              <strong style={{ color: 'rgb(153, 153, 153)', fontWeight: '100' }}>
                Recommended size:{' '}
              </strong>
              1280 x 720 pixels
            </p>
          </div>
        }
        reuploadbuttontext={'UPDATE THUMBNAIL'}
      />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px'
        }}>
        <button className="button transparent" onClick={() => onClose(false)}>
          Close
        </button>
        <button className="button primary" onClick={onClickCreate}>
          Create
        </button>
      </div>
    </div>
  );
};

export default CreateCompetitionModalFunctionality;
