import React, { useContext, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../../context/AppContext';
import Dropdown from './Dropdown/Dropdown';
import default_profile_picture from '@img/rallerpenos-image.png';
import logo_header from '@img/logo-header.svg';
import { DynamicSearch, Icon } from '@common';
import NotificationsDropdown from './NotificationsDropdown/NotificationsDropdown';
import GameSwitcher from './GameSwitcher/GameSwitcher';
import Axios from 'axios';
import GlitchText from '../../_common/GlitchText/GlitchText';
import TranslationDropdown from './TranslationDropdown/TranslationDropdown';
import useTranslated from '../../../helpers/translationHelper';
import TimezoneDropdown from './TimezoneDropdown/TimezoneDropdown';
import { getGameBackground } from '../../../utils/additional-helpers';
import { BiPlus } from 'react-icons/bi';
import CreateCompetitionModal from '../../_modals/CreateCompetitionModal/CreateCompetitionModal';
import { useEffect } from 'react';

const toggleSidebar = (sidebarOpen, setSidebarOpen) => {
  setSidebarOpen(!sidebarOpen);
  localStorage.setItem('sidebarOpen', !sidebarOpen);
};

const HeaderButtons = () => {
  const history = useHistory();
  const { authenticated, setAuthenticated, user, setShowAuth } = useContext(AppContext);

  const Leagues_Admin = useTranslated('id_top_bar_(settings)_0');
  const Ghost_Dashboard = useTranslated('id_top_bar_(settings)_1');
  const Settings = useTranslated('id_top_bar_(settings)_2');
  const Logout = useTranslated('id_top_bar_(settings)_1');

  const logout = () => {
    localStorage.removeItem('sessionToken');
    setAuthenticated(false);
    history.push('/');
  };
  let usertranslate1 = useTranslated('id_top_bar_3');
  let usertranslate2 = useTranslated('id_top_bar_4');
  const [showOrgModal, setShowOrgModal] = useState(false);
  if (authenticated && user.state) {
    return (
      <React.Fragment>
        <Link to={`/players/${user.state._id}`} className="header-profile-link">
          <img
            src={user.state.avatarImage || default_profile_picture}
            style={{ borderRadius: '15px' }}
            alt=""
          />
          <span className="bold">{user.state.nickname || user.state.firstName}</span>
        </Link>

        <NotificationsDropdown />

        <Dropdown
          label={<Icon name="cog" />}
          render={() => (
            <>
              {user.state.admin && (
                <>
                  <a
                    href="https://admin.leagues.gg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-settings-link">
                    <Icon name="cog" /> {Leagues_Admin}
                  </a>
                  <a
                    href="https://pr.leagues.gg/ghost/#/posts"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="header-settings-link">
                    <Icon name="cog" /> {Ghost_Dashboard}
                  </a>
                </>
              )}
              <Link to="/account" className="header-settings-link">
                <Icon name="cog" /> {Settings}
              </Link>
              <div onClick={logout} className="header-settings-link">
                <Icon name="logout" /> {Logout}
              </div>
            </>
          )}
        />
        <TimezoneDropdown />
        <TranslationDropdown />

        <button className="button wide-primary" onClick={() => setShowOrgModal(true)}>
          <BiPlus style={{ fontSize: '1.5em', marginBottom: '-5px' }} />
          NEW COMPETITIONS
        </button>
        {showOrgModal ? (
          <CreateCompetitionModal open={showOrgModal} onClose={setShowOrgModal} />
        ) : null}
      </React.Fragment>
    );
  } else {
    setShowAuth('login');
  }

  return (
    <React.Fragment>
      <TranslationDropdown />

      {/* //     <button className="button bordered medium ml-3 mr-3" onClick={() => setShowAuth("login")}>
  //     {usertranslate1}
  //     </button>
  //     <button className="button primary medium" onClick={() => setShowAuth("register")}>
  //     {usertranslate2}
  //     </button> */}
    </React.Fragment>
  );
};

const Header = ({ sidebarOpen, setSidebarOpen }) => {
  return (
    <React.Fragment>
      <header className="global-header">
        <div className="header-logo">
          <button className="menu-icon" onClick={() => toggleSidebar(sidebarOpen, setSidebarOpen)}>
            {sidebarOpen ? <Icon name="menu-close" /> : <Icon name="menu-open" />}
          </button>
          <div style={{ cursor: 'pointer' }}>
            <Link to="/overview" className="logo-text">
              <GlitchText animateOnHover>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logo_header} alt="Leagues logo" />
                  <strong className="uppercase logo__text">{useTranslated('id_top_bar_0')}</strong>
                </div>
              </GlitchText>
            </Link>
          </div>
          <span className="header-game-switcher-bleft">&nbsp;</span>
          <GameSwitcher />
        </div>

        <div className="header-actions">
          <HeaderButtons />
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
